<template>
    <div class="otherContain"  @touchstart="touchstart" @touchend="touchEnd">
        <img src="../assets/newBackage.png" style="width: 100%;z-index: -100">

        <div class="other-image-content">
            <span class="butRight" @click="myinfoShow()">我的</span>
        </div>

        <div class="otheritem-list">
            <div v-if="list.length == 0">
                <h3 style="color: #fff;margin-top: 30px">红包已领完，明天再来！</h3>
            </div>
            <div class="item-second-list" v-else>


                <div
                        v-for="(item,index) in list" :key="index"
                        class="items"
                        @click="clickHongbao(item.linkUrl,item.duration,item.taskId)"
                >
                    <div class="daitijia_con" v-if="item.status == 'SUBMIT' ">
                        <img src="../assets/daitijiao.png">
                    </div>
                    <div class="hongbao-money">
                        <span class="money-item" >{{item.amount+"元"}}</span>
                    </div>
                    <div class="hongbao-title">
                        <span>
                            {{item.title}}
                        </span>

                    </div>


                </div>


            </div>


        </div>

      <van-overlay  class="other_dialogContain" :show="timeNotEnoughShow" >
        <div class="stimeNotEn_contain" @click.stop>
          <img src="../assets/hb_backgroud.png" style="width: 80%;height: 100%;">
          <div class="stimeNotEn_text">
            <span class="text_1">离任务完成还差一步</span>
            <span class="text_2">{{"浏览"+this.pageInfo.duration+"秒返回即可领取红包"}}</span>
            <van-button color="linear-gradient(180deg, #FF3E2B 0%, #FE995B 100%)"
                        round
                        class="upload_su_btn stimeNotEn_btn"
                        @click="goTaskAgain">领取红包</van-button>

            <span @click="closeDialog">残忍离开</span>
          </div>

        </div>
      </van-overlay>



      <van-overlay  class="other_dialogContain" :show="sucuessShow" >
        <div class="stimeNotEn_contain" @click.stop>
          <img src="../assets/hb_backgroud.png" style="width: 80%;height: 100%;">
          <div class="stimeNotEn_text">
            <span class="text_1">任务已完成</span>
            <span class="text_2">红包已到账到我的钱包，请前往提取</span>
            <van-button color="linear-gradient(180deg, #FF3E2B 0%, #FE995B 100%)"
                        round
                        class="upload_su_btn stimeNotEn_btn"
                        @click="goTaskContinue">继续领取红包</van-button>
          </div>

        </div>
      </van-overlay>



    </div>

</template>
<script>
    import {md5} from "js-md5";
    import {AssistantApi} from "@/api/api";
    const { getNewList,getDraw } = AssistantApi
    export default {
        name:'otherActive',
        data(){
            return{
                list:[],
                pageInfo:{
                  startTime:0,
                  duration:-1,
                  pageUrl:"",
                  taskId:""
                },
                timeNotEnoughShow:false,
                sucuessShow:false,
              startX: 0, // 触摸开始的X坐标
              endX: 0, // 触摸结束的X坐标
              threshold: 150 // 设置滑动多少距离后触发返回事件
            }
        },
        created(){
          this.gethongbaoList();
          //页面返回事件
          ap.onResume(() => {
            if (this.pageInfo.duration > 0) {
              if (Date.now() - this.pageInfo.startTime > this.pageInfo.duration * 1000) {

                this.getDrawHongbao()
              } else {

               this.timeNotEnoughShow = true;
               this.pageInfo.startTime = Date.now();
              }
            }else if(this.pageInfo.duration == 0){

              this.getDrawHongbao()
            }
          })
        },
        methods:{
            //获取红包列表请求
            gethongbaoList(){
                // ap.alert("开始")
                getNewList("msg").then((response) => {
                    this.list = response.data;
                }).catch((error) => {
                    console.error("请求失败:", error);
                });
            },
            clickHongbao(url,duration,taskId){

              if(duration == undefined || duration == null|| duration == 0){

                this.pageInfo.taskId = taskId;
                this.pageInfo.duration = 0;
                ap.pushWindow(url);
              }else{
                this.pageInfo = {
                  duration:duration,
                  pageUrl:url,
                  startTime:Date.now(),
                  taskId:taskId
                }
                ap.pushWindow(url);
              }
            },
          closeDialog(){
            this.pageInfo = {
              duration:-1,
              pageUrl:"",
              startTime:0,
              taskId:""
            }
              this.timeNotEnoughShow = false;
          },
          goTaskAgain(){
                this.timeNotEnoughShow = false;
            this.pageInfo.startTime = Date.now();
            ap.pushWindow(this.pageInfo.pageUrl);
          },
          goTaskContinue(){
            this.sucuessShow = false;
            this.gethongbaoList();
          },
          //领取任务
          getDrawHongbao(){
              let timesamp = +new Date();
              const msg = {
                taskId:this.pageInfo.taskId,
                timestamp: timesamp,
                sign: md5(this.pageInfo.taskId + timesamp+"dfsdanjsnfsk134453")
              }
              getDraw(msg).then((response) => {
                this.pageInfo = {
                  duration:-1,
                  pageUrl:"",
                  startTime:0,
                  taskId:""
                }

                this.sucuessShow = true;
              }).catch((error) => {
                Notify('任务提交失败，请联系管理员！');
              });
          },
          touchstart(e){
            this.startX = e.touches[0].clientX;
          },
          touchEnd(e){
            this.endX = e.changedTouches[0].clientX;
            if (this.endX - this.startX > this.threshold) {
              this.$router.push('/home');
            }
          },
            myinfoShow(){
                this.$router.push('/myinfo');
            }        }
    }
</script>
<style>
    .otherContain{
        width: 100%;
        height: 100%;
        position: relative;

    }
  .otheritem-list{
      position: absolute;
      top: 260px;
      left: 0px;
      width: 100%;
      height: calc(100% - 200px);
      box-sizing: border-box;
      padding: 35px 20px;
      background-size: cover;
  }
    .otherContain  .item-second-list{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 10px;
        box-sizing: border-box;

    }
    .otherContain .items{
        background-image: url(../assets/newHongbao.png);
        background-position: center;
        width: 40%;
        background-size: cover;
        height: 150px;
        margin: 10px 10px;
        position: relative;
    }
    .otherContain .daitijia_con{
        position: absolute;
        right: 6px;
        top: 6px;
        z-index: 10;
    }
    .hongbao-title{
        position: absolute;
        width:  80%;
        position: absolute;
        top: 20px;
        margin: auto;
        left: 10%;
        font-size: 14px;
        line-height: 40px;
        color: #882614;
        font-weight: bold;
        display: flex;
        align-items: center;
        line-height: 20px;
        height: 40px;
    }
    .otherContain   .hongbao-money{
        position: absolute;
        width: 100%;
        height: 90%;
        display: flex; /* 使用Flexbox布局 */
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中，如果也需要水平居中的话 */
        top: 10%;
        font-size: 0.8rem;
        left: 0%;
        color: #F13827;
        z-index: 10;

    }
    .otherContain .money-item{
        width: 50px;
        height: 50px;
        background-image: url(../assets/hongbao_circle.png);
        background-size: cover;
        line-height: 50px;
    }
    .van-overlay{
        z-index: 1000;
    }
    .wrapper {
        width: 80%;
        height: 80%;
        margin: auto auto;
    }
    .butRight{
        position: absolute;
        top: 120px;
        color: #C82838;
        font-size: 16px;
        background-color: #fff;
        opacity: 0.8;
        padding: 8px 8px;
        right: 0px;
        font-weight: bold;
        border-radius: 5px 0px 0px 5px;
    }
    .other_dialogContain{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .stimeNotEn_contain{
      margin: auto auto;
      position: relative;
    }
    .stimeNotEn_text{
      width: 80%;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      bottom: 20px;
      left: 10%;
    }
    .text_1{
      font-size: 20px;
      font-weight: bolder;
    }
    .text_2{
      font-size: 16px;
      color: #F9333A;
      font-weight: bolder;
    }
    .stimeNotEn_btn{
      width: 60%;
    }
    .other-image-content{
        position: absolute;
        z-index: 2000;
        top: 10px;
        right: 0px;
    }

</style>
