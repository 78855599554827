import axios from 'axios'
import { Notify } from 'vant';
import store from "@/store";
// import qs from 'qs'

// 新建一个 axios 实例
const service = axios.create({
  // `baseURL` 将自动加在 `url` 前面，可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
  baseURL: process.env.VUE_APP_BASE_CHAT_API, // url = base url + request url
  timeout: 50000, // 请求超时(毫秒)
  // withCredentials: true, // 当跨域请求时发送cookie
  headers: { 'If-Modified-Since': '0',
    'Cache-Control': 'no-cache'
  } // 解决ie浏览器get请求会被缓存，导致结果页面无法正常显示的问题
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.authCode) {
      // 让每个请求携带 token
      // ['X-Token'] 是一个自定义头键
      // 请根据实际情况进行修改
      config.headers['token'] = store.getters.authCode
    }

    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * 通过自定义代码确定请求状态
   * 可以通过HTTP状态码来判断状态
   */
  (response) => {
     const { status, statusText, headers } = response
    const res = response.data
    if (status === 200) {
      // 请求正常
      if (res.code === 0) {
        return res
      }else {
        Notify({ type: 'warning', message: res.msg });
        return Promise.reject(res) // 返回一个带有拒绝原因的Promise对象,error表示Promise被拒绝的原因。
      }
    } else {
      Notify({ type: 'warning', message: '请求异常，请稍后重试' });
      return Promise.reject(res) // 返回一个带有拒绝原因的Promise对象,error表示Promise被拒绝的原因。
    }
  },
  (error) => {
    Notify({ type: 'warning', message: '请求异常，请稍后重试' });
    return Promise.reject(error) // 返回一个带有拒绝原因的Promise对象,error表示Promise被拒绝的原因。
  }
)

export default service
