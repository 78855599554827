
<template>
  <div class="taskinfo-contian" ref = "taskinfoRef" >

    <div class="taskinfo-sencode-contian" @touchstart="touchstart" @touchend="touchEnd">
      <div class="taskinfo-third-contian">
        <div class="task-head-box">
          <div class="task-head-box-content">
            <div >
              <img :src="taskInfo.logoUrl">
            </div>
            <div class="url_content">
              <span>{{taskInfo.title}}</span>
              <div class="mount-content">
                <span>￥</span>
                <span class="mount—num">{{taskInfo.amount}}</span>
              </div>
            </div>

          </div>
          <div class="head-item-contain">
            <div class="head-item">
              <h3>{{taskInfo.limitTime+"分"}}</h3>
              <p>限时完成</p>
            </div>

            <div class="head-item">
              <h3>{{taskInfo.auditTime+"天"}}</h3>
              <p>审核限时</p>
            </div>


            <div class="head-item">
              <h3>{{taskInfo.finishNum+"个"}}</h3>
              <p>已接数量</p>
            </div>

            <div class="head-item">
              <h3>{{ taskInfo.remainNum+"个"}}</h3>
              <p>剩余数量</p>
            </div>

          </div>
        </div>
<!--        <div class="upload-content" v-show="taskInfo.taskStatus != 'INIT'" >-->

        <div class="upload-content" >
          <div class="upload-contain-head">
            <h3>操作步骤</h3>
          </div>



          <div class="step-contian " style="margin: 0px auto"  v-for="(item,index) in taskInfo.certifyInfoList" :key="index">
            <div class="step-contian-left">
              <span class="setp_icon">{{index + 1}}</span>
              <div class="driveLine"></div>
            </div>
            <div class="step-contian-right" >
              <div class="step_text">
                 <span >
                    {{item.textDescribe}}
                  </span>
              </div>
              <div class="url_content_group" v-if="item.model == 'btn'">
                <div>
                  <img :src="taskInfo.logoUrl" width="30px" height="30px">
                </div>
                <div class="url_item" >
                  <span >{{taskInfo.assistType == "LINK" ? taskInfo.linkUrl:taskInfo.commandUrl}}</span>
                </div>
                <div>
                  <van-button class="copy_btn" :color="taskInfo.linBtnColor"    round type="info" @click="toBtn_click(taskInfo)">
                    {{taskInfo.assistType == "LINK" ? "复制连接":"复制口令"}}
                  </van-button>
                </div>

              </div>

              <div class="upload-conatian" v-else-if="item.model == 'uploadPic'">
                <div class="upload-content-item">
                  <van-image class="vanImage"
                             fit="contain"
                             :src="require('../assets/'+taskInfo.demandType+'/'+index+'.jpg')"
                             @click="imagePreview(require('@/assets/'+taskInfo.demandType+'/'+index+'.jpg'))"
                  />
                </div>
                <div class="upload-content-item">
                  <van-image class="vanImage"
                             fit="contain"
                             :src="item.key"
                             @click="imagePreview(item.key)"
                  />

                </div>
              </div>
              <div class="text-contain" v-else>
                <van-field v-model="item.key"   :disabled = "taskInfo.taskStatus != 'SUBMIT'"  />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="btns">
        <div class="btn-group">
          <van-button type="info" color="#FFFFFF" class="quitBtn" round  @click="submitAduitFn('REJECT')">审核驳回</van-button>
          <van-button round type="info" color="#E3543D" @click="submitAduitFn('FINISH')"  >审核通过</van-button>
        </div>
      </div>
    </div>

  </div>

</template>
<script >
import {AssistantApi} from "@/api/api";
 const {getTaskAuditDetailInfo,submitAudit} = AssistantApi;
 import {ImagePreview, Notify} from 'vant';
  export default {
    name: 'TaskInfoAudit',
    props:{
    },
    data() {
      return{
        fileList:[],
        imgUrlMap:{
                "ALIPAY":require('@/assets/alipay_background.png'),
                "TAOBAO":require('@/assets/taobao_background.png'),
                "PDD":require('@/assets/pdd_backgorund.png'),
                 "ELE":require('@/assets/elm_backgorund.png'),
                "MEI_TUAN":require('@/assets/meituan_background.png'),
        },

        logoUrlMap:{
          "ALIPAY": require('@/assets/alipay_logo.png'),
          "TAOBAO":require('@/assets/taobao_logo.svg'),
          "PDD":require('@/assets/pinduoduo_logo.svg'),
          "ELE":require('@/assets/elm_logo.svg'),
          "MEI_TUAN":require('@/assets/meituan_logo.svg'),
          "XIGUA":require("@/assets/xigua_logo.png"),
          "HANGBAN":require("@/assets/hangban_logo.png"),
          "SHOUQIANMA":require("@/assets/shouqianma_logo.png"),
          "JINGYINGMA":require("@/assets/jingyingma_logo.png"),
          "DAZHONGDIANPIN":require("@/assets/dazhongdianpin.png"),
          "TIANMAO":require("@/assets/tianmao_logo.png"),
        },
        btnColorMap:{
          "ALIPAY":"#1677FF",
          "TAOBAO":"#ff5000",
          "PDD":"#f4010a",
          "ELE":"#0097ff",
          "MEI_TUAN":"#fecf01",
          "XIGUA":"#fe163e"
        },
        taskInfo:{
          certifyInfoList:[]
        },
        recordId:"",
        uploadScuessShow:false,
        startX: 0, // 触摸开始的X坐标
       endX: 0, // 触摸结束的X坐标
       threshold: 150 // 设置滑动多少距离后触发返回事件
      }
    },
    created() {
      this.recordId = this.$route.params.taskId;
       this.getTaskDetailInfo(this.$route.params.taskId);
    },
    methods:{
      touchstart(e){
         this.startX = e.touches[0].clientX;
     },
     touchEnd(e){
       this.endX = e.changedTouches[0].clientX;
       if (this.endX - this.startX > this.threshold) {
         this.closeDialog();
       }
     },

      formaterDetail() {
        this.taskInfo["logoUrl"] = this.logoUrlMap[this.taskInfo.demandType];
        this.taskInfo["imgUrl"] = this.imgUrlMap[this.taskInfo.demandType];
        this.taskInfo["linBtnColor"] = this.imgUrlMap[this.taskInfo.demandType];
      },
      closeDialog(){
          this.$router.push(`/infoExamine`);
      },
      imagePreview(src){
        ImagePreview([src]);
      },
      getTaskDetailInfo(recordId){
        const msg = {
          recordId:recordId
        }
        getTaskAuditDetailInfo(msg).then((response) => {
          this.taskInfo = response.data;
          this.formaterDetail()
        }).catch((error) => {
          console.error("请求失败:", error);
        });
      },
      submitAduitFn(state) {
        const msg = {
          "recordId": this.recordId ,
          "status":state
        }
        submitAudit(msg).then((response) => {
          Notify({ type: 'success', message: '提交成功！' });
          this.$router.push(`/infoExamine`);
        }).catch((error) => {
          console.error("请求失败:", error);
        })
      }

    }
  }
</script>
<style scoped>
.taskinfo-contian{
  width: 100%;
  height: 100%;
  background: #fff;
}
.taskinfo-sencode-contian{
  width: 100%;
  height: 100%;
  opacity: 1;
  background: linear-gradient(180deg, rgba(255, 205, 193, 0.8) 0%, rgba(255, 245, 242, 0.8) 50%, rgba(252, 243, 203, 0.8) 100%);
  box-sizing: border-box;
}
.task-head-box{
  width: 100%;
  height: 170px;
  box-sizing: border-box;
  padding: 10px 10px;
  border-radius: 20px;
  opacity: 1;
  background: rgba(255,255,255,0.66);
  margin-bottom: 10px;
}


.task-head-box-content{
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  text-align: left;
  align-items: center;
}
.task-head-box-content img{
  width: 55px;
  height: 55px;
}
.url_content{
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  width: calc(100% - 60px);
  font-weight: bolder;
  justify-content: space-between;
  margin-right: 10px;
}
.mount-content{
  color: #F60503;
}
.mount—num{
  font-size: 1.8rem;
}

.url_content_group{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  box-sizing: border-box;

}
.url_item{
  border-radius: 20px;
  opacity: 1;
  background: #EFEFEF;
  padding: 5px 5px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
  width: 150px;
  font-size: 14px;
  width: calc(100% - 150px);
  margin: 0px 10px 0px 0px;
}
.copy_btn{
  height: 30px!important;
}
.upload-content{
  width: 100%;
  border-radius: 20px;
  opacity: 1;
  background: rgba(255,255,255,0.66);
  margin: 10px auto 20px auto;
  padding: 0px 10px 20px 10px;
  box-sizing: border-box;

}
.upload-contain-head{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 5px;
}

.upload-conatian{
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px auto ;
  padding: 0px 20px;
  box-sizing: border-box;
}
.upload-content-item{
  width: 45%;
  height: 100%;
}
.vanImage{
  height: 100%;
}

.upload-content-item >>> .van-uploader__upload{
 /* width: calc(100vw * 0.4);
  height: 200px !important;*/
}

.upload-content-item >>>  .van-uploader__preview-image{
  width: 65px !important;
  height: 65px !important;
}
.tip_contain{
  border-radius: 20px;
  opacity: 1;
  background: #FFFFFF;
  width: 100%;
  height: 300px;
}
.btns{
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
}
.btn-group{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.btns button{
  width: 150px;
}
.btns .quitBtn{
  color: #063b69 !important;
}
.expireBtn{
  width: 70% !important;
  color: #063b69 !important;
}
.taskinfo-third-contian{
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px;
  box-sizing: border-box;
}
.sucess_contain{
  width: 100%;
  height: 400px;
  margin: 80px auto;
  position: relative;
  text-align: center;
}
.upload_su_btn{
  position: absolute;
  bottom: 20px;
  width: 200px;
  left: calc(40% - 50px);
}
.head-item-contain{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.head-item{
  width: 25%;
}
.head-item h3{
  margin: 10px auto !important;
}
.head-item p{
  margin: 10px auto !important;
}
.head-item-contain  > :not(:last-child) {
   border-right: 1px solid #ffd8ce;
   padding-right: 5px;
}
.head-item-contain p{
  color: #999999;
}
.step_head_content{
  text-align: left;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.setp_icon{
  background-color: #ffd8cf;
  border-radius: 50%;
  color: #654e4e;
  width: 30px;
  height: 30px;
  text-align: center;
  display: block;
  line-height: 30px;
}
.step-contian{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.step-contian-right{
  width: calc(100% - 45px);

}
.text-contain{
  margin: 10px auto;
  padding: 0px 20px;
}
.conut-content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #E76460;
  position: fixed;
  bottom: 55px;
  left: 0px;
  height: 20px;
  width: 100%;
}
.conut-content .van-count-down{
  color: #E76460;
}
.step_text{
  margin-left:10px ;

}
.driveLine{
  height: calc(100% - 20px);
  width: 2px;
  background: #ffe4de;
  margin-left: 15px;
}
.step-contian-right{
  text-align: left;
}
.upload-content-item >>> .van-uploader__upload{
  width: calc(100vw * 0.4);
  height: 200px !important;
}

.upload-content-item >>> .van-uploader__preview-image{
  width: calc(100vw * 0.4) !important;
  height: 200px !important;
}
</style>
