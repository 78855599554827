<template>
  <div class="fedHis-contain" @touchstart="touchstart" @touchend="touchEnd">
    <h3>反馈历史信息</h3>
    <div class="fedHis-content">
      <van-empty description="暂无数据哦" v-if="list.length == 0"  />

      <van-list v-else>
        <van-cell v-for="(item,index) in list" :key="index" @click="historyDetail(item)">
          <template slot="title">
            <div class="cellColum">
              <div class="cellColum-left">
                <van-icon name="more-o"  color="#e76e51" />
                <span class="ellipsis">{{item.content}}</span>
              </div>
              <div class="cellColum-right">
                <van-icon name="arrow" />
              </div>
            </div>

          </template>



        </van-cell>
      </van-list>
    </div>

    <van-overlay :show="show" class="detilInfo-feed">
     <div class="detil_contain">
       <h3>反馈信息详情</h3>
       <div>
         <van-field
             class="fedDetailInp"
             v-model="curItem.message"
             rows="4"
             autosize
             disabled
             type="textarea"
             maxlength="50"
             placeholder="请输入反馈意见或建议(必填)"
             show-word-limit
         />
       </div>
         <h5 style="text-align: left;margin: 5px 0px">图片详情</h5>
        <div class="detail-img">

         <div class="upload-content-item" v-for="(item,index) in curItem.img" :key="index">
           <van-image class="vanImage"
                      fit="contain"
                      :src="item"
                      @click="imagePreview(item)"
           />
         </div>
       </div>

       <div style="text-align: center;margin: 30px auto">
         <van-button type="primary" round  color="#192B81"   @click="show = false">返回</van-button>
       </div>
     </div>
    </van-overlay>

  </div>

</template>
<script>
import {ImagePreview, Notify} from 'vant';
import {AssistantApi} from "@/api/api";
const {feedaHistory} = AssistantApi;
export default {
  name:"feedHistory",
  data(){
    return{
      list:[],
      show:false,
      curItem:{},
      startX: 0, // 触摸开始的X坐标
      endX: 0, // 触摸结束的X坐标
      threshold: 150 // 设置滑动多少距离后触发返回事件
    }
  },
  created() {
    this.getFeedHistory();
  },
  methods:{
    touchstart(e){
      this.startX = e.touches[0].clientX;
    },
    touchEnd(e){
      this.endX = e.changedTouches[0].clientX;
      if (this.endX - this.startX > this.threshold) {
        this.$router.push('/feedBack');
      }
    },
    getFeedHistory(){
      feedaHistory({}).then((response) => {
        this.list = response.data;
      }).catch((response) => {
        console.log(response);
      });
    },
    imagePreview(src){
      ImagePreview([src]);
    },
    historyDetail(item){
      this.show = true;
      this.curItem={
        message:item.content,
        img:item.img,
      }

    }
  }
}
</script>
<style>
.fedHis-contain{
  width: 100%;
  height: 100%;
  background:  linear-gradient(180deg, rgba(255, 205, 193, 0.8) 0%, rgba(255, 245, 242, 0.8) 50%, rgba(252, 243, 203, 0.8) 100%);;
  padding: 10px 10px;
  box-sizing: border-box;
}
.ellipsis {
   text-align: left;
   width: 200px; /* 设置元素宽度 */
   white-space: nowrap; /* 确保文本在一行内显示 */
   overflow: hidden; /* 隐藏超出容器的内容 */
   text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
 }
.detilInfo-feed{
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.fedHis-content{
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
  border-radius: 25px;
}
.fedHis-contain h3{
  margin: 10px auto;
  color: #6c2626;
}
.detil_contain{
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  background: linear-gradient(180deg, rgba(255, 205, 193, 0.8) 0%, rgba(255, 245, 242, 0.8) 50%, rgba(252, 243, 203, 0.8) 100%);
  box-sizing: border-box;
}
.detail-img{
  width: 100%;
  max-height: 450px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fedHis-contain .textareaBox{
  background: #F4F4F4;
  border: 1px solid #dddada;
  width: 100%;
  height: 150px;
}
.fedHis-contain .upload-content-item{
  width: 45%;
  height: 100%;
}
</style>
