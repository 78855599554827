<template>
  <div class="home-contain" >

    <div class="img-content">
      <img src="../assets/caishen.png" width="60%" height="100%">
    </div>
    <div class="item-list">
      <div v-if="list.length == 0">
        <h3 style="color: #fff;margin-top: 30px">红包已领完，明天再来！</h3>
      </div>
      <div class="item-second-list" v-else>


        <div
            v-for="(item,index) in list" :key="index"
            class="items" @click="hongbaoClick(item)"
        >
          <div class="daitijia_con" v-if="item.status == 'SUBMIT' ">
            <img src="../assets/daitijiao.png">
          </div>
          <div class="hongbao-money">
          </div>

        </div>


      </div>


    </div>

    <!--<van-overlay :show="dialogShow">
      <taskInfo ref="overlayRef"  :isvip = "true" :itemDetail="itemDetail"  @closeDialog ="closeDialog"></taskInfo>
&lt;!&ndash;      <div class="wrapper" >
         <img src="../assets/fininshed.png" width="100%" @click = "dialogShow = false">
      </div>&ndash;&gt;
    </van-overlay>-->
  </div>
</template>
<script>
import { Notify } from 'vant';
import store from "@/store";
// import {AssistantApi} from "@/api/api";
// const { gethongbapList,getToken,bangdingShare } = AssistantApi
import qs from 'qs';

export default {
  name: 'Demo',
  data() {
    return {
      list:["https://ur.alipay.com/_6rCUDxMXny6JMGfdCQLvyI","https://ur.alipay.com/_6HxpMOLkihY4GaGKB0Dwi5","https://m.tb.cn/h.TY4VmUZ","https://m.tb.cn/h.T1ZPIOF"]
    }
  },
  created() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
    document.body.appendChild(script);
  },
  methods:{
    hongbaoClick(item){
      // this.itemDetail = item;
      //this.itemDetail = item;
      ap.pushWindow(item);
    }
  }
}


</script>
<style>
.home-contain{
  background: url("../assets/background.png");
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
}
.img-content{
  position: fixed;
  width: 100%;
  height: 200px;
  margin-top: 10px;
}

.item-list{
  position: absolute;
  top: 260px;
  left: 0px;
  width: 100%;
  height: calc(100% - 200px);
  box-sizing: border-box;
  padding: 35px 20px;
  background-image:url(../assets/kuang.png);
  background-size: cover;

}
.item-second-list{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 10px;
  box-sizing: border-box;

}
.items{
  background-image: url(../assets/hongbao.png);
  background-position: center;
  width: 40%;
  background-size: cover;
  height: 150px;
  margin: 10px 10px;
  position: relative;
}
.img-content{
  position: fixed;
  width: 100%;
  height: 200px;
  margin-top: 10px;
}
.hongbao-money{
  position: absolute;
  width: 100%;
  height: 90%;
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果也需要水平居中的话 */
  top: 10%;
  font-size: 0.8rem;
  left: 0%;
  color: #F13827;

}
.money-item{
  width: 50px;
  height: 50px;
  background-image: url(../assets/hongbao_circle.png);
  background-size: cover;
  line-height: 50px;
}
</style>