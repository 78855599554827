<template>
    <div class="myinfo"  @touchstart="touchstart" @touchend="touchEnd">

<!--      <van-nav-bar
          title="我的"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
      />-->
      <div class="contain">
        <div class="myinfo-head">
          <div style="width: 5rem;height: 5rem;display: flex;align-items: flex-end">
            <van-image
                    round
                    width="4rem"
                    height="4rem"
                    :src="require('../assets/user.png')"
            />
          </div>

          <div class="myinfo-head-name">
            <div class="user-fo">
              <span class="user-title">财富掌门</span>
              <van-button size="small" color="#ff7350" round style="margin-left: 50px" type="info" @click="checkAlipay">{{userInfo.alipayAccount == null?"绑定支付宝":"修改支付宝"}}</van-button>
            </div>
            <div class="id_content">
              <span class="user-id">ID:{{userInfo.userId}}</span>
            </div>
          </div>
        </div>

        <div class="tixian-content">
          <div>
            <span>可提现金额:</span>
          </div>
          <div>
            <span class="amount" >{{userInfo.amount}}</span>
            <span>元</span>
          </div>
          <div>
            <van-button round type="info" class="tixian-btn" color="#FFFFFF" @click="fetchMoney(userInfo.amount)">提现</van-button>
          </div>
        </div>

        <div class="apply-content">
          <h5 style="text-align: left">我的审核</h5>
          <div class="apply-content-list taskList">
            <van-empty description="暂无数据"
                       image-size="0px"  v-if="list.length == 0" />
            <van-list v-else
                      finished-text="没有更多了"
            >

              <van-cell v-for="(item,index) in list" :key="index" :title="item.taskName" >

                <template slot="label" >
                  <span class="taskList_right_time">{{item.amount+"元"}}</span>
                </template>
                <div class="taskList_right">
                  <van-tag :color="item.color" :text-color="item.textColor" >{{item.taskState}}</van-tag>
                  <span class="taskList_right_time">{{item.createTime}}</span>
                </div>




              </van-cell>

            </van-list>
          </div>
        </div>


        <div class="apply-content">
          <h5 style="text-align: left">我的提现记录</h5>
          <div class="apply-content-list">
            <van-empty description="暂无数据"
                       image-size="0px"
                       v-if="amount_history_list.length == 0" />
            <van-list v-else
                      finished-text="没有更多了"
            >

              <van-cell v-for="(item,index) in amount_history_list" :key="index" :title="item.amount" >
                <template slot="label">
                  <span class="amount-text">{{item.createTime}}</span>
                </template>
                <van-tag :color="item.color" :text-color="item.textColor" >{{item.taskState}}</van-tag>

              </van-cell>


            </van-list>
          </div>
        </div>


        <div class="yaoqing-content">
          <div  class="yaoqing-item-content"   v-if="inviteList.length >0">
            <div class="yaoqing-item-head">
              <h3>我的邀请</h3>
              <div class="yaoqing-item-head-button">
                <van-button color="#FF5000"  round type="info">分享</van-button>
              </div>

            </div>
            <div class="yaoqing-item-head-total">
              <span>已成功邀请10位好友</span>
              <span>助力金额50元</span>
            </div>
            <div class="yaoqing-item-list">
              <div v-for="(item,index) in inviteList" :key="index" class="yaoqing-item">
                <div class="yaoqing-item-name">
                  <van-image class="yaoqing-item-img"
                             round
                             width="2rem"
                             height="2rem"
                             src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  />
                  <span>115****2234</span>
                </div>
                <div class="yaoqing-item-money">
                  <span>助力30元</span>
                </div>
              </div>

            </div>
          </div>
          <div v-else class="img-contain">
            <img src="../assets/yaoqing.png" width="100%" height="100%">
            <div class="img-contain-button">
              <van-button color="#FF5000"  round type="info" @click="quickShare">立即邀请</van-button>
            </div>

          </div>
        </div>



        <van-dialog v-model="moneyMountShow" title="提现" @confirm = "submitMoney">
          <van-field v-model="moneyMount" type="number" label="输入金额" />
        </van-dialog>



        <van-dialog v-model="bangdingAlipayShow"
                    title="绑定支付宝"
                    show-cancel-button
                    :before-close = "dialogClose"
                    @confirm="confirmFn">
          <van-form validate-first  ref="bangdingFormRef" >
            <!-- 通过 pattern 进行正则校验 -->
            <van-field
                v-model="alipayInfo.alipayAccount"
                name="alipayAccount"
                label="支付宝账号"
                :rules="[{ required: true, message: '支付宝账号' }]"
            />
            <van-field
                v-model="alipayInfo.alipayUserName"
                name="alipayUserName"
                label="支付宝姓名"
                :rules="[{ required: true, message: '支付宝姓名' }]"
            />
          </van-form>
        </van-dialog>





        <van-dialog v-model="checkTixianShow"
                    title="提现"
                    @confirm="startTransfer"
                    show-cancel-button>
          <div class = "tixian_contain">
            <van-row class="user-title_row">
              <van-col span="24">即将发起转账，请账号信息是否正确:</van-col>
            </van-row>

            <van-row class="detail_row">
              <van-col span="10">支付宝账号</van-col>
              <van-col span="14" >

                 <span class="detail">
                  {{userInfo.alipayAccount}}
                </span></van-col>
            </van-row>
            <van-row class="detail_row">
              <van-col span="10">支付宝用户名</van-col>
              <van-col span="14"  >
                <span class="detail">
                  {{userInfo.alipayUserName}}
                </span>
              </van-col>
            </van-row>

            <van-row class="tip_row">
              <van-col span="24">*一旦发起转账将无法修改，请确认再操作。</van-col>
            </van-row>
          </div>

        </van-dialog>

        <van-overlay :show="getSucuesShow" @click="getSucuesShow = false">
          <div class="sucess_contain" @click.stop>
             <img src="../assets/sucuess_hongbao.png" style="width: 80%;height: 100%;margin: 40px auto">
          </div>
        </van-overlay>



      </div>

    </div>
</template>
<script>
  import {AssistantApi} from "@/api/api";
  import { Toast } from 'vant';
  const { getuserInfo, getuserTaskList,getUserAmountHistory,bangdingAlipay ,getMoney} = AssistantApi
  const moment = require('moment');
 export default {
   name: 'MyiInfo',
   data() {
     return {
       userInfo:{
         userId:"",
         amount:0,
         alipayAccount:""
       },
       stateMap:{
         "AUDITING":"审核中",
         "FINISH":"已完成",
         "REJECT":"已驳回"
       },
       colorMap:{
         "AUDITING":"#FDE9E9",
         "FINISH":"#E3ECFF",
         "REJECT":"#E4FFE1"
       },

       textColorMap:{
         "AUDITING":"#D72624",
         "FINISH":"#0055FF",
         "REJECT":"#10BF00"
       },

       list:[],
       amount_history_list:[],
       inviteList:[],
       bangdingAlipayShow:false,
       checkTixianShow:false,
       getSucuesShow:false,
       alipayInfo:{
         alipayAccount:"",
         alipayUserName:"",
       },
       moneyMountShow:false,
       showShare:false,
       moneyMount:0,
       startX: 0, // 触摸开始的X坐标
       endX: 0, // 触摸结束的X坐标
       threshold: 150 // 设置滑动多少距离后触发返回事件
     }
   },
   mounted() {
   },

   created() {
    this.getUserInfo();
    this.getuserTaskList();
    this.getUserMountHistpry();
   },
   methods:{
     touchstart(e){
       this.startX = e.touches[0].clientX;
     },
     touchEnd(e){
       this.endX = e.changedTouches[0].clientX;
       if (this.endX - this.startX > this.threshold) {
         this.$router.push('/home');
       }
     },
     //弹窗关闭回调函数
     dialogClose(action,done){
       if (action == 'confirm') {
         done(false)
       } else {
         done(true)
       }
     },
     checkAlipay(){
       this.bangdingAlipayShow = true;
     },
     getUserInfo(){
       const msg = {

       }
       getuserInfo(msg).then((response) => {
         this.userInfo = response.data;
       }).catch((error) => {
         console.error("请求失败:", error);
       });
     },

     getuserTaskList() {
       const msg = {

       }
       getuserTaskList(msg).then((response) => {
        let data  = response.data;
         for (const responseKey in data) {
           this.list.push(this.formaterState(data[responseKey]));
         }
       }).catch((error) => {
         console.error("请求失败:", error);
       });
     },

     formaterState(obj){
       let obs = {
           taskState:this.stateMap[obj.status],
           color:this.colorMap[obj.status],
           textColor:this.textColorMap[obj.status]
       }
       obs = {...obs,...obj};
       obs["createTime"] = moment(obs.createTime).format("YYYY-MM-DD HH:mm:ss");
       return obs;

      },
     getUserMountHistpry(){
       const msg = {}
       this.amount_history_list = [];
       getUserAmountHistory(msg).then((response) => {
         let data  = response.data;
         for (const responseKey in data) {
           let res = this.formaterState(data[responseKey]);
           res["amount"] =   "支付宝提现"+ res["amount"] + "元";
           this.amount_history_list.push(res);

         }
         console.log(this.amount_history_list)
       }).catch((error) => {
         console.error("请求失败:", error);
       });

     },

     confirmFn(){
       this.$refs.bangdingFormRef.validate().then(() => {
         const msg = {
           alipayAccount:this.alipayInfo.alipayAccount,
           alipayUserName:this.alipayInfo.alipayUserName
         }
         bangdingAlipay(msg).then((response) => {
           this.$toast.success("绑定成功");
           this.getUserInfo();
           this.bangdingAlipayShow = false;
         }).catch((error) => {
           console.error("请求失败:", error);
         });
       }).catch((err) => {
         // 验证不通过，err是错误信息
         console.log('验证不通过，错误信息：', err);
         // 这里可以做你想做的提示，例如使用Toast组件
         this.$toast(err[0].message);
       });

     },

     fetchMoney(mount){
       if(this.userInfo.alipayAccount){
         this.moneyMount = this.userInfo.amount;
         this.moneyMountShow =true

       }else{
         this.$toast("请先绑定支付宝账号");
       }
     },
     submitMoney(){
       const regex = /^(0\.[0-9][1-9]|[1-9]\d*(\.\d+)?)$/;
       if(!regex.test(this.moneyMount)  || this.moneyMount > this.userInfo.amount){
         this.$toast("请输入正确的金额");
         return;
       }
       this.checkTixianShow = true;
     },
     startTransfer(){
       const msg = {
        amount:this.moneyMount
      }
      getMoney(msg).then((response) => {
        this.getSucuesShow = true;
        this.getUserInfo();
        this.getUserMountHistpry();
      }).catch((error) => {
        console.error("请求失败:", error);
      });
     },
     quickShare(){
       if(navigator.userAgent.indexOf("AlipayClient")===-1){
         ap.alert('请在支付宝钱包内运行')
       }else{
         if((Ali.alipayVersion).slice(0,3)>=8.1){
           Ali.share({
             //渠道名称。支持以下几种：Weibo/LaiwangContacts/LaiwangTimeline/Weixin/WeixinTimeLine/SMS/CopyLink
             'channels': [{
               name: 'ALPContact',   //支付宝联系人,9.0版本
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"", //图片url
                 url:"https://xm-static.tmo68.cn/#/home?shareId="+this.userInfo.userId,   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               }
             },{
               name: 'ALPTimeLine', //支付宝生活圈
               param: {
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               }
             },{
               name: 'Weixin', // QQ、微信、微信朋友圈分享的链接需要https开头的链接 否则会报人气大爆发
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               } // 分享出去的是吱口令 搜索显示imageUrl + title + content
             },{
               name: 'WeixinTimeLine', // QQ、微信、微信朋友圈分享的链接需要https开头的链接 否则会报人气大爆发
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               } // 分享出去的是吱口令 搜索显示imageUrl + title + content
             },{
               name: 'QQ', // QQ、微信、微信朋友圈分享的链接需要https开头的链接 否则会报人气大爆发
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               } // 分享出去的是吱口令 搜索显示imageUrl + title + content
             }, {
               name: 'Weibo', // QQ、微信、微信朋友圈分享的链接需要https开头的链接 否则会报人气大爆发
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               } // 分享出去的是吱口令 搜索显示imageUrl + title + content
             }, {
               name: 'SMS', // QQ、微信、微信朋友圈分享的链接需要https开头的链接 否则会报人气大爆发
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个福利群，每日都有红包雨",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               } // 分享出去的是吱口令 搜索显示imageUrl + title + content
             },{
               name: 'DingTalkSession', // QQ、微信、微信朋友圈分享的链接需要https开头的链接 否则会报人气大爆发
               param: {   //请注意，支付宝联系人仅支持一下参数
                 contentType: 'url',    //必选参数,目前支持支持"text","image","url"格式
                 content:"你的好友邀请你入群啦～群里福利官每日准点给大家发红包哦",    //必选参数,分享描述
                 iconUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original",   //必选参数,缩略图url，发送前预览使用,
                 imageUrl:"https://mdn.alipayobjects.com/huamei_zjbdv1/afts/img/A*S2XWR4htsD8AAAAAAAAAAAAADg6FAQ/original", //图片url
                 url:"alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP",   //必选参数，卡片跳转连接
                 title:"这个红包群，每日都可领最少8个红包",    //必选参数,分享标题
                 memo:""   //透传参数,分享成功后，在联系人界面的通知提示。
               } // 分享出去的是吱口令 搜索显示imageUrl + title + content
             }, {
               name: 'CopyLink', //复制链接
               param: {
                 url: 'alipays://platformapi/startapp?appId=68687451&url=%2Fwww%2Fbc-join.html%3Foid%3D2024120322542700016834%26businessId%3D2024120322542700016834%26businessType%3D18%26source%3DTINYAPP'
               }
             }]
           }, function(result) {
           });
         }else{
           alert('请在钱包8.1以上版本运行');
         }
       }
     }
   }
 }
</script>
<style scoped>
.myinfo{
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(249 222 216 / 70%) 0%, rgba(255, 255, 255, 0.8) 100%)
}
.myinfo-head{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.contain{
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  overflow: auto;
}
.myinfo-head-name{
  text-align: left;
  margin-left: 5px;
  line-height: 30px;
  margin-top: 20px;
  max-width: calc(100% - 5rem);
}
.user-title{
  font-family: Source Han Sans;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.04em;
  font-variation-settings: "opsz" auto;
  color: #000000;
  font-weight: bolder;
}
.user-id{
  font-family: Source Han Sans;
  font-size: 12px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0.04em;
  font-variation-settings: "opsz" auto;
  color: #666666;
}
.tixian-content{
  width: 100%;
  height: 150px;
  background-image: url("../assets/tixianbackground.png");
  background-size: cover;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #EDEDED;
  padding: 10px 20px;
  font-size: 14px;
  text-align: left;
  box-sizing: border-box;
}
.amount{
  font-size: 30px;
}
.tixian-btn{
  width: 80px;
  height: 30px;
  color: #FF805F;
}
.tixian-content .van-button__text{
  color: #FF805F;
}
.img-contain-button .van-button__text{
  color: #FFFFFF;
}
.img-contain-button .van-button,.yaoqing-item-head-button  .van-button{
  height: 30px !important;
}

.apply-content{
  width: 100%;
  max-height: 220px;
  margin: 20px auto;
}
.apply-content h5{
  margin: 10px auto;
}
.apply-content-list{
  width: 100%;
  max-height: 170px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  opacity: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  opacity: 1;
  box-sizing: border-box;
  padding: 10px 10px;
}
.yaoqing-content{
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  margin: 20px auto;
}
.van-cell__title{
  text-align: left;
}
.van-tag{
  padding: 5px 10px;
}
.img-contain{
  position: relative;
}
.img-contain-button{
  position: absolute;
  right: 20px;
  top: 35px;
}
.yaoqing-item-content{
  margin: 10px auto;
  width: 100%;
  max-height: 500px;
  border-radius: 20px;
  opacity: 1;
  background: #FFFAF4;
  box-sizing: border-box;
  border: 2px solid #FDCB4A;
  padding: 10px 10px;
}
.yaoqing-item-head-total{
  width: 100%;
  height: 35px;
  border-radius: 10px;
  opacity: 1;
  background: #FF5B5B;
  color: #FFFFFF;
  line-height: 35px;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  margin: 10px auto;
}
.yaoqing-item-list{
  max-height: 300px;
  overflow: auto;
  width: 100%;
}
.yaoqing-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  margin: 5px auto;
  border-bottom: 1px solid  rgba(0, 0, 0, 0.12);
}
.yaoqing-item-name{
  display: flex;
  align-items: center;
}

.yaoqing-item-head{
  width: 100%;
  position: relative;

}
.yaoqing-item-head-button{
  position: absolute;
  right: 10px;
  top: 0px;
}

.myinfo .van-empty{
  padding: 10px 0px;
}
.tixian_contain{
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  padding: 10px 20px;
}
.user-title_row{
  margin: 20px auto ;
  font-size: 1rem;
  font-weight: bolder;
  text-align: left;
}
.tip_row{
  text-align: left;
  font-size: 0.8rem;
  color: #d35838;
  text-align: left;
  margin-top: 40px;
}
.detail_row{
  margin: 10px auto;
  font-size: 0.9rem;
  text-align: left;
}
.detail_row .detail{
  color: #e11414;
  font-size: 1rem;
  font-weight: bold;
}
.sucess_contain{
  width: 100%;
  height: 400px;
}
.id_content{
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}
.text-contain .van-field{
  border: 1px solid #CCC;
  border-radius: 10px;
}
.user-fo{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.taskList_right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.taskList .van-cell__label{
  margin-top: 15px;
}
.taskList_right_time{
  margin-top: 10px;
}
.apply-content-list .van-list{
  max-height: 150px;
  overflow: auto;
}
</style>
