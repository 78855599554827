




<template>

  <div class="indexPage">
    <div class="info-contain">
      <router-view ></router-view>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {Notify} from "vant";
import {AssistantApi} from "@/api/api";
const {getToken } = AssistantApi
export default {
  name: 'IndexPage',
  components: {
  },
  props: {
    msg: String
  },
  mounted() {
  },

  created(){
    //store.dispatch("constant/setAuthCode", "A01275B6280B4124A71ABF052252D4D9xm");
    // this.loadAlipaySDK();
  },
  data(){
    return{
      active:0
    }

  },
  methods:{
   /* loadAlipaySDK(){
      let that = this;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
      script.onload = function() {
        ap.getAuthCode({
          appId: '2021004190685903',
          scopes: ['auth_base'],
        }, function (res) {
          that.getToken(res.authCode)
        },function (err){
        });
      }
      document.body.appendChild(script);
    },
    //根据authcode获取token
    getToken(data){
      const msg= {
        authCode : data
      }
      getToken(msg).then((response) => {
        store.dispatch("constant/setAuthCode", response.data.token);
      }).catch((error) => {
        Notify({ type: 'warning', message: error.msg });
      });
    },*/

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.indexPage{
  width: 100%;
  height: 100%;
}
.info-contain{
  width: 100%;
  height:100%;
}
</style>
