import Vue from 'vue'
import App from './App.vue'
import Vant, {Notify} from 'vant'
import 'vant/lib/index.css'
import Router from 'vue-router'
import router from "@/router";
import "./assets/font/iconfont.css"
import VueClipboard from 'vue-clipboard2';
import store from "@/store";
import {AssistantApi} from "@/api/api";
const {getToken } = AssistantApi
Vue.use(Vant)
Vue.config.productionTip = false
Vue.use(Router)
Vue.use(VueClipboard);
router.beforeEach((to, from, next) => {
  // 可以在这里添加路由跳转前的逻辑
  console.log('即将离开路径 ' + from.path + ' 进入路径 ' + to.path)
  //store.dispatch("constant/setAuthCode", "54644E07B25C46E283F817BD43C2BE25xm");
  console.log(store.getters.authCode )
  if(store.getters.authCode == null  || store.getters.authCode == ""){
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
    script.onload = function() {
      ap.getAuthCode({
        appId: '2021004190685903',
        scopes: ['auth_base'],
      }, function (res) {
        const msg= {
          authCode : res.authCode
        }
        getToken(msg).then((response) => {
          store.dispatch("constant/setAuthCode", response.data.token);
          next()
        }).catch((error) => {
          Notify({ type: 'warning', message: error.msg });
        });
      },function (err){
      });
    }
    document.body.appendChild(script);
  }else{
    next()
  }

})
new Vue({
  router,
  created() {
    window.addEventListener('popstate', this.redirectToDefaultPage);
  },
  render: h => h(App),
  methods: {
    edirectToDefaultPage() {
      // 重定向到默认页面的路径，例如 '/home'
      this.$router.push('/home');
    }
  },
  beforeDestroy() {
    // 清理事件监听器
    window.removeEventListener('popstate', this.redirectToDefaultPage);
  }
}).$mount('#app')
