
<template>
  <div class="money-contain">
    <h3>提现审核页面</h3>
    <div class="listContain">
      <van-empty description="暂无数据哦" v-if="list.length == 0"  />
      <van-list v-else>
        <van-cell v-for="(item,index) in list" :key="index">
          <template slot="title">
            <div class="cellColum">
              <div class="cellColum-left">
                <span><sub>￥</sub></span>
                <span class="amount-text">{{item.amount}}</span>
              </div>
              <div class="cellColum-right">
                <van-button round type="info" color="#063b69" @click="submitTixian(item.transId,'FINISH')">通过</van-button>
                <van-button round type="info" color="#E3543D" @click="submitTixian(item.transId,'REJECT')">驳回</van-button>
              </div>
            </div>
          </template>
          <template slot="label">
           <div style="text-align: left">
             <span>支付宝账号：</span>
             <span>{{item.alipayAccount}}</span>
           </div>
          </template>
        </van-cell>
      </van-list>

    </div>
  </div>
</template>
<script>
import {Notify} from "vant";
import {AssistantApi} from "@/api/api";
const {withdrawAudit,getWithdrawList} = AssistantApi;
export default {
  name:"moneyAudit",
  data() {
    return {
      list:[]
    }
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      this.list = [];
      getWithdrawList({}).then((response) => {
        if(response.data){
          this.list = response.data;
        }

      }).catch((response) => {
        Notify({ type: 'warning', message: response.msg });
      });
    },
    submitTixian(transId,state){
      const msg = {"transId":transId,"status":state}
      withdrawAudit(msg).then((response) => {
        Notify({ type: 'success', message: '提交成功！' });
        this.getList();
      }).catch((error) => {
        Notify({ type: 'warning', message:"请求失败！" });
      });
    }
  }

}
</script>
<style>
.money-contain{
  width: 100%;
  height: 100%;
  background:  linear-gradient(180deg, rgba(255, 205, 193, 0.8) 0%, rgba(255, 245, 242, 0.8) 50%, rgba(252, 243, 203, 0.8) 100%);;
  padding: 10px 10px;
  box-sizing: border-box;
}
.money-contain .listContain{
  width: 100%;
  max-height: calc(100% - 90px);
  overflow: auto;
  border-radius: 20px;
}
.money-contain .cellColum{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.money-contain .van-cell{
  border-bottom: 1px solid #d6d1d1;
}
.money-contain .cellColum-right button{
  margin-right: 10px;
}
.money-contain  .amount-text{
  font-size: 30px;
}
</style>
