<template>
 <div class="contain">
   <diV class="content">
     <h3>反馈信息</h3>
   <van-field
       class="textareaBox"
       v-model="message"
       rows="4"
       autosize
       type="textarea"
       maxlength="50"
       placeholder="请输入反馈意见或建议(必填)"
       show-word-limit
   />

   <div class="uploadBox">
     <van-uploader v-model="fileList"
                   max-count="3"
                   @delete="(file, detail) => deleteImg(file, detail)"
                   :after-read="afterRead" />
   </div>

   </diV>
   <div v-if="!isDetail">
     <van-button round  type="primary" class="btns" color="#192B81" @click="submit">提交</van-button>
      <div class="feedlistory" @click="feedHistory">
       <span>查看历史反馈信息</span>
      </div>
   </div>

 </div>
</template>

<script>
import {Notify} from "vant";
import {AssistantApi} from "@/api/api";
const {submitFedback} = AssistantApi;

export default {
  name: 'feedBack',
  data(){
    return{
        message:"",
        image:"",
        fileList:[],
        fileBaseList:[],
        isDetail:false
    }
  },
  created() {
  },
  methods:{
    afterRead(file){
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        const formData = {
          file: base64String,
          name: file.file.name
        };
        this.fileBaseList.push(base64String)

      };
      reader.readAsDataURL(file.file);
    },
    deleteImg(file,detail){
      this.fileBaseList.splice(detail.index, 1);
    },
    submit(){
      if(this.message == ""){
        Notify({ type: 'warning', message: '请输入意见或建议！' });
        return;
      }
      const msg = {
        content:this.message,
        img:this.fileBaseList
      }
      submitFedback(msg).then((response) => {
        this.message = "";
        this.fileList = [];
        this.fileBaseList=[];
        Notify({ type: 'success', message: '提交成功' });

      }).catch((response) => {
        Notify({ type: 'warning', message: response.msg });
      });
    },
    feedHistory(){
      this.$router.push('/feedHistory');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contain{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
}
.content{
  width: 100%;
  height: 70%;
}
.textareaBox{
  background: #F4F4F4;
  border: 1px solid #dddada;
  width: 100%;
  height: 150px;
}
.phoneBox{
  margin-top: 10px ;
  background: #F4F4F4;
  border: 1px solid #dddada;
  width: 100%;
}
.uploadBox{
  width: 100%;
  margin-top: 10px;
  text-align: left;
}
.btns{
  width: 30%;
  letter-spacing: 10px;
}
.feedlistory{
  color: #192B81;
  margin-top: 10px;
}
.contain h3{
  margin: 10px auto;
}
</style>
