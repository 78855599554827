<template>
  <div class="notFoundContain"  @touchstart="touchstart" @touchend="touchEnd">
     <img src="../assets/404_bg.png">
  </div>

</template>
<script>
import {AssistantApi} from "@/api/api";
const { get404Request } = AssistantApi
 export default {
   name:'notFound',
   data(){
     return {
       startX: 0, // 触摸开始的X坐标
       endX: 0, // 触摸结束的X坐标
       threshold: 150 // 设置滑动多少距离后触发返回事件
     }
   },
   created() {
     const msg= {
       taskId:this.$route.params.taskId
     }
     get404Request(msg).then((response) => {

     }).catch((error) => {
       console.error("请求失败:", error);
     });

   },
   methods:{
     touchstart(e){
       this.startX = e.touches[0].clientX;
     },
     touchEnd(e){
       this.endX = e.changedTouches[0].clientX;
       if (this.endX - this.startX > this.threshold) {
         this.$router.push('/home');
       }
     },
   }
 }

</script>
<style>
.notFoundContain{
  width: 100%;
  height: 100%;

}
</style>
