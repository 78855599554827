import requestChat from '../utils/requestChat'
const assistant = "/xm"

// 登录页相关接口
export const AssistantApi = {
  gethongbapList(data) {
    // 查询用户会话列表
    return requestChat({
      url: assistant + '/task/list',
      method: 'post',
      data
    })
  },
  getuserInfo(data) {
    // 查询用户会话列表
    return requestChat({
      url: assistant + '/user/info',
      method: 'post',
      data
    })
  },
  getuserTaskList(data) {
    // 查询用户会话列表
    return requestChat({
      url: assistant + '/user/task/list',
      method: 'post',
      data
    })
  },
  getUserAmountHistory(data) {
    // 用户提现列表
    return requestChat({
      url: assistant + '/user/withdraw/list',
      method: 'post',
      data
    })
  },
  bangdingAlipay(data){
    //绑定支付宝账号提交接口
    return requestChat({
      url: assistant + '/user/bind',
      method: 'post',
      data
    })
  },
  uploadImage(data){
    return requestChat({
      url: assistant + '/img/upload',
      method: 'post',
      data
    })
  },
  getToken(data){
    return requestChat({
      url: assistant + '/auth',
      method: 'post',
      data
    })
  },
  getMoney(data){
    return requestChat({
      url: assistant + '/withdraw',
      method: 'post',
      data
    })
  },
  submitTask(data){
    return requestChat({
      url: assistant + '/task/audit/submit',
      method: 'post',
      data
    })
  },
  getTaskDetailInfo(data){
    return requestChat({
      url: assistant + '/task/detail',
      method: 'post',
      data
    })
  },
  participateIn(data){
    //报名任务
    return requestChat({
      url: assistant + '/task/sign',
      method: 'post',
      data
    })
  },
  bangdingShare(data){
    //分享建立关联关系
    return requestChat({
      url: assistant + '/save/relation',
      method: 'post',
      data
    })
  },
  submitFedback(data) {
    return requestChat({
      url: assistant + '/feedback/submit',
      method: 'post',
      data
    })
  },
  getAuditList(data) {
    // 用户提现列表
    return requestChat({
      url: assistant + '/task/audit/list',
      method: 'post',
      data
    })
  },
  getTaskAuditDetailInfo(data){
    return requestChat({
      url: assistant + '/task/audit/detail',
      method: 'post',
      data
    })
  },
  submitAudit(data){
    // 审核
    return requestChat({
      url: assistant + '/task/audit',
      method: 'post',
      data
    })
  },
  getWithdrawList(data){
    // 提现列表
    return requestChat({
      url: assistant + '/withdraw/tran/list',
      method: 'post',
      data
    })
  },
  withdrawAudit(data){
    // 提现审核
    return requestChat({
      url: assistant + '/withdraw/tran/audit',
      method: 'post',
      data
    })
  },
  feedaHistory(data){
    return requestChat({
      url: assistant + '/feedback/list',
      method: 'post',
      data
    })
  },
  getNewList(data){
    return requestChat({
      url: assistant + '/brow/task/lt',
      method: 'post',
      data
    })
  },
  getDraw(data){
    return requestChat({
      url: assistant + '/brow/task/rd',
      method: 'post',
      data
    })
  },
  get404Request(data){
    return requestChat({
      url: assistant + '/brow/task/fh',
      method: 'post',
      data
    })
  }
}
