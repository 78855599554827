import VueRouter from 'vue-router'
import homeInfo from "@/components/HomeInfo.vue";
import myInfo from "@/components/MyInfo.vue";
import IndexPage from "@/components/IndexPage.vue"
import TaskInfo from "@/components/TaskInfo.vue"
import Demo from  "@/components/Demo.vue"
import InfoExamine from "@/components/InfoExamine.vue";
import TaskInfoAudit from "@/components/TaskInfoAudit.vue";
import FeedBack from "@/components/FeedBack.vue";
import moneyAudit from "@/components/MoneyAudit.vue";
import FeedHistory from "@/components/FeedHistory.vue";
import otherActive from "@/components/OtherActivity.vue"
import notFound from "@/components/NotFound.vue";
export default  new VueRouter({
    routes:[{
      path:"*",
      component:otherActive
    },{
        path:"/home",
        component:otherActive
    },{
        path:"/myinfo",
        component:myInfo
    },{
        path:"/taskInfo/:taskId",
        component:TaskInfo
    },{
        path:"/infoExamine",
        component:InfoExamine
    },{
        path:"/taskInfoAudit/:taskId",
        component:TaskInfoAudit
    },{
        path:"/feedBack",
        component:FeedBack
    },{
        path:"/moneyAudit",
        component:moneyAudit
    },{
        path:"/feedHistory",
        component:FeedHistory
    },{
        path:"/otherActivity",
        component:otherActive
    },{
        path:"/notFound/:taskId",
        component:notFound
    }
    ]
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
