<template>
  <div class="contain">
    <h3> 待审核任务</h3>
    <div class="listContain">
      <van-empty description="暂无数据哦" v-if="list.length == 0"  />

      <van-list v-else>
        <van-cell v-for="(item,index) in list" :key="index"  @click="toDeatilInfo(item)">
          <template slot="title">
            <div class="cellColum">
              <div class="cellColum-left">
                <van-icon name="more-o"  color="#e76e51" />
                <span class="amount-text">{{item.taskName}}</span>
              </div>
              <div class="cellColum-right">
                <van-icon name="arrow" />
              </div>
            </div>

          </template>



        </van-cell>
      </van-list>
    </div>

  </div>
</template>
<script>
import {AssistantApi} from "@/api/api";
const {getAuditList} = AssistantApi;
import { Notify } from 'vant';
export default {
  name:"infoExamine",
  mounted(){
    this.getList();
  },
  data(){
    return{
      list:[]
    }
  },
  methods:{
    getList(){
      getAuditList({}).then((response) => {
        this.list = response.data;
      }).catch((response) => {
        Notify({ type: 'warning', message: response.msg });
      });
    },
    toDeatilInfo(item){
      this.$router.push(`/taskInfoAudit/${item.recordId}`);
    }
  }
}
</script>
<style>
.contain{
  width: 100%;
  height: 100%;
  background:  linear-gradient(180deg, rgba(255, 205, 193, 0.8) 0%, rgba(255, 245, 242, 0.8) 50%, rgba(252, 243, 203, 0.8) 100%);;
  padding: 10px 10px;
  box-sizing: border-box;
}
.listContain{
  width: 100%;
  max-height: calc(100% - 100px);
  overflow: auto;
  border-radius: 20px;
}
.cellColum{
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.cellColum-left{
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #924949
}
.cellColum-left i {
  font-size: 25px;
  margin-right: 10px;

}
.cellColum-right i{
  font-size: 25px;
  color: #cac7c7;
}
.van-cell{
  background-color: rgba(255,255,255,0.8)!important;
}
</style>
